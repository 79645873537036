(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/button-switch/assets/javascripts/button-switch.js') >= 0) return;  svs.modules.push('/components/components/button-switch/assets/javascripts/button-switch.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.components = svs.components || {};

  svs.components.ButtonSwitch = function() {
    setInitState();

    initListeners();

    function setInitState(el) {
      if (!el) {
        el = $('body');
      }

      el.find('.js-btn-switch-label input').each(function(index, element) {
        var target = $(element);

        setCheckedState(target.parent(), target.prop('checked'));
      });
    }

    function initListeners() {
      var body = $('body');

      body.on('change', '.js-btn-switch-label input', onSwitchChange);
      body.on('touchstart', '.js-btn-switch-label', onSwitchTouchStart);
    }

    function onSwitchChange(e) {
      var target = $(e.target);

      setCheckedState(target.parent(), target.prop('checked'));
    }

    function onSwitchTouchStart(e) {
      var target = $(e.target);
      var touches = e.originalEvent.touches[0];
      var delta = {
        x: 0,
        y: 0
      };
      var start = {
        x: touches.pageX,
        y: touches.pageY
      };

      target.on('touchmove', function(e) {
        var touches = e.originalEvent.touches[0];

        delta = {
          x: touches.pageX - start.x,
          y: touches.pageY - start.y
        };

        e.preventDefault();
      });

      target.on('touchend', function(e) {
        var target = $(e.target);
        var isSwipe = false;

        if (delta.x > 20) {
          target
            .find('input')
            .prop('checked', true)
            .trigger('change');
          isSwipe = true;
        } else if (delta.x < -20) {
          target
            .find('input')
            .prop('checked', false)
            .trigger('change');
          isSwipe = true;
        }

        start = delta = {
          x: 0,
          y: 0
        };

        target.off('touchmove').off('touchend');

        if (isSwipe) {
          e.preventDefault();
        }
      });
    }

    function setCheckedState(labelElement, isChecked) {
      if (isChecked) {
        labelElement.addClass('btn-switch-checked');
      } else {
        labelElement.removeClass('btn-switch-checked');
      }
    }

    return {
      setInitState: setInitState
    };
  };

  svs.components.buttonSwitch = new svs.components.ButtonSwitch();
})(svs);


 })(window);